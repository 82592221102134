<template>
	<div class="Index">
		<top-bar />
		<side-bar />
		<router-view class="container" />
	</div>
</template>

<script>
	import SideBar from './SideBar'
	import TopBar from './TopBar'

	export default {
		name: 'Index',
		components: {
			SideBar,
			TopBar
		},
		data() {
			return {}
		},
		computed: {

		},
		async mounted() {},
		methods: {}
	}
</script>

<style lang="scss" scoped>
	.Index {
		.container {
			padding: 80px 20px 60px 220px;
		}
	}
</style>
