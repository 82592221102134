<template>
	<div class="SideBar">
		<el-menu class="el-menu-vertical-demo" :default-active="routeName" :unique-opened="true" @select="select">
			<el-submenu index="首页">
				<template slot="title">
					<i class="el-icon-s-home"></i>
					<span>{{$t('116')}}</span>
				</template>

				<el-menu-item index="首页">
					<span>{{$t('116')}}</span>
				</el-menu-item>

				<!-- <el-menu-item index="排行榜">
					<span>排行榜</span>
				</el-menu-item> -->
			</el-submenu>

			<el-submenu index="战队">
				<template slot="title">
					<i class="el-icon-ice-tea"></i>
					<span>{{$t('117')}}</span>
				</template>

				<el-menu-item index="战队列表">
					<span>{{$t('118')}}</span>
				</el-menu-item>

				<el-menu-item index="战队详情">
					<span>{{$t('119')}}</span>
				</el-menu-item>

				<el-menu-item index="创建战队">
					<span>{{$t('120')}}</span>
				</el-menu-item>

				<el-menu-item index="加入申请">
					<span>{{$t('121')}}</span>
				</el-menu-item>
			</el-submenu>

			<el-submenu index="账号">
				<template slot="title">
					<i class="el-icon-s-custom"></i>
					<span>{{$t('122')}}</span>
				</template>

				<el-menu-item index="捐款">
					<span>{{$t('24')}}</span>
				</el-menu-item>

				<el-menu-item index="修改昵称">
					<span>{{$t('139')}}</span>
				</el-menu-item>

				<el-menu-item index="修改密码">
					<span>{{$t('123')}}</span>
				</el-menu-item>

				<el-menu-item index="修改邮箱地址">
					<span>{{$t('157')}}</span>
				</el-menu-item>

				<el-menu-item index="修改用户性别">
					<span>{{$t('173')}}</span>
				</el-menu-item>
			</el-submenu>
		</el-menu>
	</div>
</template>

<script>
	export default {
		name: 'SideBar',
		data() {
			return {
				routeName: null
			}
		},
		computed: {},
		watch: {
			$route() {
				this.routeName = this.$route.name;
			}
		},
		methods: {
			select(item) {
				this.$router.push({
					name: item
				});
			},
		},
		async mounted() {
			if (this.routeName === null) {
				this.routeName = this.$route.name;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.SideBar {
		$top: 70px;
		$left: 200px;

		position: fixed;
		left: 0;
		padding-top: $top;
		z-index: 100;

		.el-menu {
			width: $left;
			min-height: 100vh;

			/deep/ .el-menu-item.is-active {
				// background-color: #009688 !important;
				// color: #fff !important;
			}
		}
	}
</style>