'use strict';
import Vue from 'vue'
import SvgIcon from '@/components/Common/SvgIcon'// svg组件

// register globally
Vue.component('svg-icon', SvgIcon);

const req = require.context('../assets/icon', false, /\.svg$/);
const requireAll = requireContext => requireContext.keys().map(requireContext);
requireAll(req);

