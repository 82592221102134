import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
	localRead
} from '@/utils/tools'
import zhCn from './lang/zh-CN'
import en from './lang/en'
import ko from './lang/ko'

import zhCnLocale from 'element-ui/lib/locale/lang/zh-CN'
import enUsLocale from 'element-ui/lib/locale/lang/en'
import koLocale from 'element-ui/lib/locale/lang/ko'

Vue.use(VueI18n)

// 自动根据浏览器系统语言设置语言
let lang = localRead('lang') || navigator.language || 'en-US'

// vue-i18n 6.x+写法
Vue.locale = () => {}
const messages = {
	'zh-CN': Object.assign(zhCnLocale, zhCn),
	'en-US': Object.assign(enUsLocale, en),
	'ko': Object.assign(koLocale, ko)
}

const i18n = new VueI18n({
	locale: lang,
	messages
})


export default i18n

// vue-i18n 5.x写法
// Vue.locale('zh-CN', Object.assign(zhCnLocale, customZhCn))
// Vue.locale('en-US', Object.assign(zhTwLocale, customZhTw))
// Vue.locale('zh-TW', Object.assign(enUsLocale, customEnUs))
