const zh_CN = {
	// Register
	1: '用户注册',
	2: '请输入用户名',
	3: '用户名必须以字母开头，只能包括字母、下划线、数字，长度必须在5至10之间',
	4: '请输入密码',
	5: '密码只能包括字母、数字，长度必须在6至15之间',
	6: '请再次输入密码',
	7: '请输入邮箱',
	8: '邮箱地址必须包含@字符',
	9: '邮箱@的左边除了开头字母其他的可以是字母, 下划线, 数字， 点号 . 小杠 -',
	10: '邮箱@的右边必须有 . 点号',
	11: '在 @ 和 . 之间必须以字母, 下划线, 数字开头, 且除了开头字母其他的可以是字母, 下划线, 数字， 点号 . 小杠 -',
	12: '男',
	13: '女',
	14: '请输入邀请码',
	15: '点击这里领取邀请码',
	16: '注册',
	17: '返回登录',
	18: '点击这里给我发消息',
	// Index
	19: '亲爱的疯坦，欢迎登录',
	20: '登录后才可以查看您的具体信息哦(包括战绩、战队、资产）',
	21: '官网个人信息显示略有延迟，请以游戏数据为准',
	22: '账号注册',
	23: '游戏下载',
	24: '充值',
	25: '新手指南',
	26: '高手进阶',
	27: '官方论坛',
	28: '配置需求',
	29: '战斗方法',
	30: '敬请期待',
	31: '道具介绍',
	32: '地图介绍',
	33: '坦克介绍',
	34: '新闻',
	35: '公告',
	36: '媒体评测',
	37: '玩家攻略',
	38: '论坛热帖',
	39: '更多媒体评测',
	40: '更多',
	// Apply
	41: '选择',
	42: '撤销',
	43: '通过',
	44: '战队名',
	45: '队长',
	46: '申请人',
	47: '等级',
	48: '成立时间',
	49: '已批准通过',
	50: '已拒绝通过',
	51: '已取消加入',
	// Create
	52: '创建战队(需要 ',
	53: ' 金币',
	54: '立即去充值',
	55: '退出',
	56: '战队名由3-12个字符组成',
	57: '颜色',
	58: '预览',
	59: '创建',
	60: '已经加入战队了，如果要创建战队，须先退出或解散原战队',
	61: '战队已成功创建',
	// Detail
	62: '解散战队',
	63: '退出战队',
	64: '操作',
	65: '删除',
	66: '昵称',
	67: '等级',
	68: '排名',
	69: '积分',
	70: '胜率',
	71: '最后游戏',
	72: '已删除队员',
	73: '确认解散战队?',
	74: '提示',
	75: '确定',
	76: '取消',
	77: '战队已成功解散',
	78: '已取消',
	79: '确认退出战队?',
	80: '战队已成功退出',
	// Guild Index
	81: '选择',
	82: '加入',
	83: '名称',
	84: '队长',
	85: '队员数',
	86: '成立时间',
	87: '战队积分',
	88: '申请已经提交，请等待队长审核!',
	// Donate
	89: '充值',
	90: '充值方式',
	91: '商品数量',
	92: '立即可获赠 ',
	93: '金币',
	94: '积分赞助联系',
	95: '捐赠支持声明',
	96: '尊敬的各位朋友：',
	97: 'SuperTank作为FortressRed2最后的圣地，需要大家共同支持守护，我们将进一步完善游戏中的各类功能及修复各种可能存在的相关问题。',
	98: '为了继续维持SuperTank运行,以下费用全部为无偿赞助行为，特此声明！',
	99: '感谢您对SuperTank未来的支持！',
	100: 'SuperTank团队 2021.1.1',
	101: '正在充值中，请耐心等待处理结果...',
	102: '关闭',
	103: 'Paypal充值',
	104: '支付宝扫码充值',
	105: '二维码充值',
	106: '充值已成功',
	// MotifyPassword
	107: '请输入旧密码',
	108: '请输入密码',
	109: '密码只能包括字母、数字，长度必须在6至15之间',
	110: '请再次输入密码',
	111: '修改',
	112: '请输入邮箱',
	113: '修改密码已完成',
	// Login
	114: '用户登录',
	115: '登录',
	// SideBar
	116: '首页',
	117: '战队',
	118: '战队列表',
	119: '战队详情',
	120: '创建战队',
	121: '加入申请',
	122: '账号',
	123: '修改密码',
	124: '金钱',
	125: '胜利',
	126: '失败',
	127: '账号状态',
	// 
	128: '点击即下载',
	129: '注意',
	130: '必须安装C++ Runtime',
	131: '请点击上方按钮',
	132: '商品选择',
	133: ' 积分',
	134: '为朋友充值',
	135: '朋友的昵称',
	136: '请输入昵称',
	137: '昵称长度必须在1至6之间.',
	138: '昵称',
	139: '修改昵称',
	140: '昵称类型',
	141: '普通(300 金币)',
	142: '特殊(1500 金币)',
	143: '修改昵称已完成',
	144: '检查',
	145: '注册已完成',
	146: '标题',
	147: '作者',
	148: '忘记密码',
	149: '下一步',
	150: '邮件已发送',
	151: '输入新密码',
	152: '搜索',
	153: '忘记用户名',
	154: '请输入新邮箱地址',
	155: '请再次输入新邮箱地址',
	156: '两次输入邮箱不一致',
	157: '修改邮箱地址',
	158: '修改邮箱已完成',
	159: '新邮箱',
	160: '邮箱',
	161: '修改战队名称',
	162: '修改战队颜色',
	163: '确定修改战队名称?',
	164: '确定修改战队颜色?',
	165: '修改战队名称已成功',
	166: '修改战队颜色已成功',
	167: '转让',
	168: '确定转让战队吗?',
	169: '转让战队已成功.',
	170: '订单金额',
	171: '最低购买数量1,500金币,每次以1,500递增',
	172: '最低购买数量1,000积分,每次以1,000递增',
	173: '修改用户性别',
	174: '修改用户性别已完成',
	// ErrorCode
	1000: '成功',
	1001: '失败',
	1002: '用户名不符合规则',
	1003: '密码不符合规则',
	1004: '邮箱不符合规则',
	1005: '未加入任何战队',
	1006: '身份证不符合规则',
	1007: '用户名已存在',
	1008: '用户名不正确',
	1009: '密码不正确',
	1010: '账号已锁定',
	1011: '性别不符合规则',
	1012: '已经加入战队',
	1013: '已经申请过战队',
	1014: '铜勋章以上才可以建立战队',
	1015: '战队名称重复',
	1016: '战队不存在',
	1017: '未加入战队',
	1018: '队长才能审核',
	1019: '不是该战队的成员，不能查看列表',
	1020: '战队名称不正确',
	1021: '没有战队加入申请表',
	1022: '订单不正确',
	1023: '金币数量不正确，创建战队需要3000金币',
	1024: '邀请码不正确',
	1025: '邀请码已使用',
	1026: '队长不能够删除',
	1057: '未知的错误',
	1058: '无访问权限',
	1059: '昵称不正确',
	1060: '昵称已存在',
	1061: '昵称不符合规则',
	1062: '金币数量不正确',
	1063: '开始时间不正确',
	1064: '结束时间不正确',
	1065: '金币不正确',
	1066: '二维码类型不正确',
	1067: '二维码不正确',
	1068: '验证码不正确',
	1069: '邮箱不正确',
	1070: '超过每日重置次数上限',
}

export default {
	...zh_CN,
}