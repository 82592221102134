<template>
	<div class="TopBar">
		<div class="brand">
			<img :src="require('@/assets/Images/Layout/Logo.png')" width="150" height="50">
		</div>

		<div>
			<table width="100%" border="0" style="text-align: center;">
				<tr>
					<th>
						<span @click="onClickDiscord()" style="cursor: pointer;">
							<img :src="require('@/assets/Images/Donate/discord.png')" width="48" height="48" />
						</span>
					</th>
		
					<th style="width: 10px;"></th>
		
					<th>
						<span @click="onClickKakaoTalk()" style="cursor: pointer;">
							<img :src="require('@/assets/Images/Donate/KakaoTalk2.png')" width="48" height="48"
								style="border-radius: 10px;border: 1px solid #ddd;box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px;" />
						</span>
					</th>
		
					<th style="width: 10px;"></th>
		
					<th>
						<el-tooltip placement="left-end" effect="light">
							<div slot="content"><img :src="require('@/assets/Images/Donate/wechat2.png')"
									width="300" height="300" /></div>
		
							<span>
								<img :src="require('@/assets/Images/Donate/wechat.png')" width="48" height="48"
									style="border-radius: 10px;border: 1px solid #ddd;box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 4px;" />
							</span>
						</el-tooltip>
					</th>
				</tr>
			</table>
		</div>

		<div class="User" v-if="null != getAccount">
			<el-dropdown @command="handleCommand">
				<span class="el-dropdown-link">
					{{ getAccount }}
					<i class="el-icon-arrow-down el-icon--right"></i>
				</span>

				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="a">{{$t('89')}}</el-dropdown-item>
					<el-dropdown-item command="b">{{$t('55')}}</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TopBar',
		data() {
			return {
			}
		},
		async mounted() {
			if (null == this.$store.state.userInfo && null != this.$store.state.expireTime && this.$store.state.expireTime > ((new Date()
					.getTime()))) {
				this.$http.post("/api/user/userInfo", null).then(oResponse => {
					if (null != oResponse) {
						this.userInfo = oResponse.data

						if (this.userInfo.winCount > 0 || this.userInfo.loseCount > 0) {
							this.userInfo.winrate = this.userInfo.winCount / (this.userInfo.winCount + this
								.userInfo.loseCount)

							this.userInfo.winrate = this.userInfo.winrate.toFixed(2)

							this.userInfo.winrate *= 100
						} else {
							this.userInfo.winrate = 0
						}

						this.$store.commit('setUserInfo', this.userInfo)
					}
				})
			}
		},
		computed: {
			getAccount() {
				return this.$store.state.account;
			}
		},
		methods: {
			handleCommand(command) {
				if (command === 'a') {
					this.$router.push('/Home/User/Donate');
				} else if (command === 'b') {
					this.logout();
				}
			},
			async logout() {
				this.$store.commit('clear', null)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.TopBar {
		$top: 60px;
		$left: 200px;

		position: fixed;
		left: 0;
		top: 0;
		z-index: 101;
		height: $top;
		width: 100%;
		background: #fff3fd;
		display: flex;
		justify-content: space-between;
		box-sizing: border-box;
		// padding-right: 80px;

		.brand {
			display: flex;
			align-items: center;
			width: $left;
			padding-left: 20px;
			box-sizing: border-box;
			// background: #393D49;
		}

		.User {
			height: $top;
			display: flex;
			align-items: center;


			.el-dropdown {
				color: #808080;
				text-align: center;
				cursor: pointer;
				font-size: 20px;
			}
		}
	}

	.el-header,
	.el-footer {
		// background-color: #B3C0D1;
		color: #333;
		text-align: center;
		line-height: 60px;
		border-radius: 10px;
		margin-top: 10px;
		width: 90%;
	}
</style>