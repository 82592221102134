import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [persistedState({
		storage: window.localStorage
	})],
	state: {
		token: null,
		expireTime: null,
		account: null,
		userInfo: null,
		ShowLoginPanel: false,
	},
	mutations: {
		setToken(state, token) {
			state.token = token;
		},
		setExpireTime(state, expireTime) {
			state.expireTime = expireTime
		},
		setAccount(state, account) {
			state.account = account
		},
		setUserInfo(state, UserInfo) {
			state.UserInfo = UserInfo;
		},
		clear(state) {
			state.token = null;

			state.expireTime = null;

			state.account = null;

			state.UserInfo = null;
		}
	},
	actions: {}
})